<template>
    <div class="notFound c_content_container">
        <div class="notFound_text c_inner">
            <img src="@/assets/image/common/icon/sad_face_icon.svg" alt="404_icon" />
            <h1>404</h1>
            <p>페이지를 찾을 수 없습니다.</p>
            <p>요청하신 페이지가 사라졌거나, 잘못된 경로를 이용하셨어요</p>
            <router-link to="/" class="c_btn c_btn_primary-fill">홈으로 이동</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name : '404',
}
</script>

<style scoped>
.notFound{
    min-height: calc(100vh - var(--header-height));
}
.notFound .notFound_text{
    text-align: center;
    position: absolute;
    top: calc(50% - var(--header-height));
    left: 50%;
    transform: translate(-50%, -50%);
    word-break: keep-all;
    font-size: var(--font-large);
}
.notFound .notFound_text img{
    display: inline-block;
    width: 8rem;
}
.notFound .notFound_text h1{
    font-size: var(--font-huge);
    font-weight: var(--font-bold);
    line-height: 2;
    color: var(--primary-color);
}
.notFound .notFound_text p{
    margin-bottom: 1rem;
}
</style>
